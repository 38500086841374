<template>
  <RouterLink
    v-if="to"
    :to="to"
    v-slot="{ href, navigate, isActive, isExactActive }"
  >
    <VyButton
      :href="href"
      @click="navigate"
      v-bind="$attrs"
      class="button--md button--rounded button--left vp-w-full"
      :class="{
        'button--primary button--muted': exact ? isExactActive : isActive,
        'button--gray': !isActive || !isExactActive,
      }"
    />
  </RouterLink>

  <VyButton
    v-else
    v-bind="$attrs"
    class="button--md button--rounded button--gray button--left vp-w-full"
  />
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    exact: Boolean,
    to: [Object, String],
  },
};
</script>

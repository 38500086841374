<template>
  <component
    class="vp-w-full vp-text-gray-500 hover:vp-text-white nav__link"
    :is="product == currentProduct ? 'RouterLink' : 'a'"
    to="/"
    :href="url"
  >
    <VyButton
      :class="{
        'vp-text-white vp-border-l-[4px] vp-border-primary-500':
          product == currentProduct,
      }"
      class="button--md vp-h-14 button--center vp-flex-col vp-px-0 vp-w-full"
      :icon="icon"
      :label="label"
    />
  </component>
</template>

<script>
export default {
  props: {
    url: String,
    icon: Object,
    product: String,
    currentProduct: String,
    label: String,
  },
};
</script>

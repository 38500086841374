export const VEPAAR_LOGO_DARK_MODE =
  "https://vepaar.b-cdn.net/public/vepaar-logo-for-dark-mode.png";

export const VEPAAR_LOGO_LIGHT_MODE =
  "https://vepaar.b-cdn.net/public/vepaar-logo-for-light-mode.png";

export const GOOGLE_SIGN_IN =
  "https://vepaar.b-cdn.net/public/google-sign-in.png";

export const GOOGLE_PLAY_BADGE =
  "https://vepaar.b-cdn.net/public/google-play-badge.svg";

export const APP_STORE_BADGE =
  "https://vepaar.b-cdn.net/public/app-store-badge.svg";

export const VEPAAR_LOGO_PLACEHOLDER =
  "https://vepaar.b-cdn.net/public/vepaar-logo-placeholder.svg";

export const APPSUMO_LOGO = "https://vepaar.b-cdn.net/public/appsumo.svg";

export const VEPAAR_ICON_128 = "https://vepaar.b-cdn.net/public/icons/128.png";

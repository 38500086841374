<template>
  <div class="vp-flex vp-flex-col vp-h-full vp-justify-center">
    <Loader class="vp-mx-auto" />
    <div class="vp-flex vp-justify-center vp-mt-4">
      <img style="height: 30px" :src="$options.logo" alt="" />
    </div>
  </div>
</template>

<script>
import Loader from "../loader.vue";
import { VEPAAR_LOGO_LIGHT_MODE } from "@shared/assets/index.js";

export default {
  logo: VEPAAR_LOGO_LIGHT_MODE,
  components: {
    Loader,
  },
};
</script>

<template>
  <div class="vp-max-w-3xl">
    <div>
      <TheHeader
        title="Features of Vepaar Catalog"
        desc="Create a catalog for your store to display and manage your products."
      />

      <CardsShell>
        <TheCard
          :icon="$options.icons.Product"
          icon-color="pink"
          title="Products"
          desc="Create products whether simple, variable, or digital to match your business requirements."
        />

        <TheCard
          :icon="$options.icons.Category"
          icon-color="cyan"
          title="Categories"
          desc="Craft a product catalog with all the categories you need to showcase your product range."
        />

        <TheCard
          :icon="$options.icons.Badge"
          icon-color="warning"
          title="Badges"
          desc="Create and assign Badges to emphasize, draw attention to your key selling products."
        />

        <TheCard
          :icon="$options.icons.Attribute"
          icon-color="danger"
          title="Attributes"
          desc="Define and apply attributes like size, color, or weight to your variable products, with the option to create custom attributes."
        />

        <TheCard
          :icon="$options.icons.Cash"
          icon-color="danger"
          title="Charges"
          desc="Add and configure product-specific charges like taxes, platform fees, additional fees easily."
        />

        <TheCard
          :icon="$options.icons.Inventory"
          icon-color="blue"
          title="Inventory"
          desc="Easily manage inventory so that a particular product can be available."
        />
      </CardsShell>
    </div>
  </div>
</template>

<script>
import {
  Product,
  Badge,
  Cash,
  Category,
  Attribute,
  Inventory,
} from "icons/icons.js";
import TheCard from "components/src/features/_card.vue";
import TheHeader from "components/src/features/_header.vue";
import CardsShell from "components/src/features/_cards-shell.vue";
export default {
  components: {
    TheCard,
    TheHeader,
    CardsShell,
  },
  icons: {
    Category,
    Product,
    Cash,
    Badge,
    Attribute,
    Inventory,
  },
};
</script>

<template>
  <Slideover
    :id="`list-settings-${endpoint}`"
    title="List Settings"
    :size="300"
  >
    <!-- Attrs not updating, therefore rerendering using key -->
    <Provider
      :key="`provider-settings-${endpoint}`"
      :provide="{ setPerPage, updateAttr, localPerPage, attrs, attrSettings }"
    >
      <div class="vp-p-4 vp-space-y-4">
        <VListPerPage :parent="instance" v-slot="{ options, value, change }">
          <Field label="Per Page">
            <div class="field-block">
              <select @input="change(parseInt($event.target.value))">
                <option
                  v-for="(option, index) in options"
                  :key="`${index}--${option.value}`"
                  :selected="value === option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
            <!-- <SSelect :value="value" @input="change($event)" :options="options" /> -->
          </Field>
        </VListPerPage>

        <Field label="Columns">
          <VListAttributes
            class="vp-divide-y vp-rounded vp-border"
            :parent="instance"
          >
            <template #attr="{ attr, update, settings }">
              <label
                class="vp-flex vp-justify-between vp-items-center vp-py-2 vp-cursor-pointer vp-px-3 hover:vp-bg-gray-100"
                :class="
                  settings?.visible ? 'vp-text-gray-500' : 'vp-text-gray-400'
                "
              >
                <span class="vp-text-xs vp-font-semibold">
                  {{ attr.label }}
                </span>
                <Icon
                  :name="
                    settings?.visible
                      ? $options.icons.CheckboxChecked
                      : $options.icons.Checkbox
                  "
                  class="vp-w-5 vp-h-5"
                />

                <input
                  class="vp-absolute vp-w-0.5 vp-h-0.5 vp-opacity-5"
                  type="checkbox"
                  :checked="settings?.visible"
                  @input="update('visible', $event.target.checked)"
                />
              </label>
            </template>
          </VListAttributes>
        </Field>
      </div>
    </Provider>
  </Slideover>
</template>

<script>
import { Checkbox, CheckboxChecked } from "icons/icons.js";

import Field from "../form/field.vue";
import Icon from "../icon.vue";
import Slideover from "../slideover/index.vue";
import Provider from "./provider.vue";

export default {
  components: {
    Field,
    Icon,
    Slideover,
    Provider,
  },
  icons: {
    Checkbox,
    CheckboxChecked,
  },
  props: {
    instance: Object,
    endpoint: String,
  },
  inject: ["setPerPage", "updateAttr", "localPerPage", "attrs", "attrSettings"],
};
</script>
<style scoped>
.field-block {
  color: #000;
  width: 100%;
  height: 37px;
  outline: 0;
  padding: 0;
  border: 0 solid #b0bec5;
  border-radius: 4px;
  min-width: 0px;
  font-size: 14px;
  overflow: hidden;
  background-color: #eceff1;
  position: relative;
}
.field-block select {
  color: #000;
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border-radius: 8px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  padding-right: 32px;
  z-index: 2;
  height: 100%;
  vertical-align: top;
  position: relative;
  outline: none;
}
.field-block::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 12px;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  border-style: solid;
  border-color: #607d8b;
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  z-index: 1;
}
</style>

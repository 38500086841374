<template>
  <div class="vp-flex vp-items-center vp-gap-6">
    <div
      class="vp-shrink-0 vp-w-16 vp-h-16 vp-p-4 vp-rounded-2xl vp-flex vp-items-center vp-justify-center"
      :class="iconClass"
    >
      <component :is="icon" class="vp-size-full" />
    </div>
    <div>
      <h5 class="vp-font-bold">{{ title }}</h5>
      <p class="vp-text-gray-500 vp-text-sm">
        {{ desc }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    iconColor: String,
    icon: Object,
  },
  data() {
    return {
      iconClasses: {
        pink: "vp-text-pink-500 vp-bg-pink-100 vp-bg-opacity-30",
        warning: "vp-text-warning-500 vp-bg-warning-100 vp-bg-opacity-30",
        success: "vp-text-success-500 vp-bg-success-100 vp-bg-opacity-30",
        cyan: "vp-text-cyan-500 vp-bg-cyan-100 vp-bg-opacity-30",
        danger: "vp-text-danger-500 vp-bg-danger-100 vp-bg-opacity-30",
        blue: "vp-text-blue-500 vp-bg-blue-100 vp-bg-opacity-30",
      },
    };
  },
  computed: {
    iconClass() {
      return this.iconClasses[this.iconColor];
    },
  },
};
</script>

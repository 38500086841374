<template>
  <div
    :class="{
      [$options.sizes[size]]: true,
    }"
    class="vp-bg-gray-100 vp-overflow-hidden"
  >
    <div class="vp-flex vp-justify-center vp-items-center vp-h-full vp-w-full">
      <template v-if="value">
        <img
          v-if="imgUrl"
          :src="imgUrl"
          :alt="alt"
          class="vp-w-full vp-h-full"
          :class="{
            'vp-object-cover': fit == 'cover',
            'vp-object-contain': fit == 'contain',
          }"
        />
        <VpIcon
          v-else
          class="vp-text-gray-300 vp-w-7 vp-h-7"
          :name="$options.icons[type]"
        />
      </template>

      <template v-else>
        <div class="vp-size-8">
          <VpIcon
            class="vp-text-gray-300 vp-w-7 vp-h-7"
            :name="$options.icons.Image"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Document, Image } from "icons/icons.js";

export default {
  icons: {
    Document,
    Image,
  },
  sizes: {
    64: "vp-h-16 vp-w-16 vp-rounded",
    96: "vp-h-24 vp-w-24 vp-rounded",
    "16x9": "vp-aspect-w-16 vp-aspect-h-9 vp-rounded",
    "1x1": "vp-aspect-w-1 vp-aspect-h-1 vp-rounded",
  },
  props: {
    value: [File, Object],
    type: {
      type: String,
      default: "Document",
    },
    alt: String,
    size: {
      default: "64",
      type: String,
    },
    fit: {
      type: String,
      default: "cover",
    },
  },
  computed: {
    imgUrl() {
      if (this.isFile) {
        return this.blobUrl;
      } else if ((this.value.mimetype || "image").includes("image")) {
        return this.value.url;
      } else {
        return null;
      }
    },
    isFile() {
      return this.value instanceof File;
    },
    blobUrl() {
      if (this.isFile) {
        return window.URL.createObjectURL(this.value);
      } else {
        return null;
      }
    },
  },
};
</script>

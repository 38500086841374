import "./vee-validate";
import "./global";
import Vue from "vue";

/**
 * Portal
 */
import PortalVue from "portal-vue";
/**
 * Popover
 */
import vTooltip from "v-tooltip";
import VayuVue from "vayu-vue";

/**
 * Vue Form
 */
import vueForm from "@7span/vue-form";

/**
 * VayuVue
 */
Vue.use(VayuVue, {
  field: {
    classList(el) {
      const state = el.$parent.flags;
      if (state?.validated && state?.invalid) {
        return "field--danger";
      } else {
        return null;
      }
    },
    alert(el) {
      return el.$parent.errors?.[0];
    },
  },
  select: {
    placeholder: "— Select —",
  },
});

Vue.use(PortalVue);

Vue.use(vTooltip, {
  defaultBoundariesElement: document.body,
});

Vue.use(vueForm, {
  errorAdapter: (error) => {
    console.error(error);
    const {
      graphQLErrors: [err],
    } = error;

    /**========================================================================
     * *                              NOTE
     * Scroll to erorr block.
     * Need to set timeout here as the error block is only added to DOM
     * once the error is retured.
     *========================================================================**/
    setTimeout(() => {
      const errorEl = document.querySelector(".error");
      if (errorEl) {
        errorEl.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);

    return {
      fields: err?.fields || {},
      message: err?.message || "There was an error!",
    };
  },
});
